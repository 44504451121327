/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 0;
$body-sticky-padding: 0;


/* ==========================================================================
   containers
   ========================================================================== */
$container-padding_sm: 0 60px;

$container-small-max_width_xl: 700px;


/* ==========================================================================
   content
   ========================================================================== */
$content-padding: 10vh 0;

/* content > h2
   ========================================================================== */
$content-h2-font_size: $font-size-base * 1.125;

/* content > h3
   ========================================================================== */
$content-h3-font_size: $font-size-base * 1.125;

/* content > h4
   ========================================================================== */
$content-h4-font_size: $font-size-base * 1.125;

/* content > h5
========================================================================== */
$content-h5-font_size: $font-size-base * 1.125;

/* content > h6
========================================================================== */
$content-h6-font_size: $font-size-base * 1.125;

/* content > ul.custom-list
   ========================================================================== */
$content-custom-list-item-padding: 0 0 0 35px;

/* content > ul.custom-list > icon */
$content-custom-list-item-icon: '\f00c';
$content-custom-list-item-icon-font_size: 24px;
$content-custom-list-item-icon-color: $secondary;
$content-custom-list-item-icon-color--dark: $tertiary;


/* ==========================================================================
   mini-sab
   ========================================================================== */
$mini-sab-padding: 15px 30px 35px 30px;
$mini-sab-background: $gray-600;


/* ==========================================================================
   footer
   ========================================================================== */
$footer-background: $dark;

/* footer > footer-top
   ========================================================================== */
$footer-top-padding: 5vh 0 10vh 0 !important;
$footer-top-background: $white;

/* footer > footer-main
   ========================================================================== */
$footer-main-padding: 10vh 0 5vh 0 !important;
$footer-main-background: $dark;

/* footer > footer-bottom
   ========================================================================== */
$footer-bottom-padding: 5vh 0 2vh 0 !important;
$footer-bottom-background: $dark;

/* footer > footer-socket
   ========================================================================== */
$footer-socket-padding: 2vh 0 7vh 0;


/* ==========================================================================
   card
   ========================================================================== */
$card-background: transparent;
$card-border: 0;
$card-base-border-radius: 0;
$card-box_shadow: 0 3px 6px rgba($black, .25);

/* card > card-image
   ========================================================================== */
$card-image-background: $white;

/* card > card-body
   ========================================================================== */
$card-body-padding: 30px;

$card-body-background: $white;

/* card > card-caption
   ========================================================================== */
$card-caption-text_align: center;

/* card > card-title */
$card-title-font_size: $font-size-base * 1.2;
$card-title-text_transform: uppercase;

/* card > card-description */
$card-description-max_height: 70px;
$card-description-font_size: $font-size-base * .875;

/* card > card-buttons
   ========================================================================== */
$card-buttons-margin: 30px 0 0 0;

/* card > card-overlay
   ========================================================================== */
$card-overlay-background: transparent;

/* card > card-overlay-title */
$card-overlay-title-text_shadow: none;
$card-overlay-title-color: $body-color;

/* card > card-overlay-subtitle */
$card-overlay-subtitle-text_shadow: none;
$card-overlay-subtitle-color: $body-color;

/* card > card-overlay-description */
$card-overlay-description-max_height: unset;
$card-overlay-description-text_shadow: none;
$card-overlay-description-color: $body-color;


/* ==========================================================================
   owl-carousel
   ========================================================================== */
$carousel-align_items: flex-end;

$carousel-min_height_mobile: 200px;
$carousel-min_height: 50vh;
$carousel-min_height_lg: 100vh;

$carousel-item_padding: 15vh 0 0 0;
$carousel-item_padding_lg: 15vh 0 0 0;

$carousel-caption-padding: 15px 30px;
$carousel-caption-padding_sm: 15px 60px;
$carousel-caption-background: $white;
$carousel-caption-text_align: right;

$carousel-title-margin: 0;
$carousel-title-font_family: $font-family-dancing-script;
$carousel-title-font_size: $font-size-base * 1.5;
$carousel-title-font_size_sm: $font-size-base * 2;
$carousel-title-font_weight: $font-weight-base;
$carousel-title-text_shadow: none;
$carousel-title-color: $body-color;

$carousel-subtitle-font_size: $font-size-base;
$carousel-subtitle-font_weight: $font-weight-medium;
$carousel-subtitle-text_transform: uppercase;
$carousel-subtitle-text_shadow: none;
$carousel-subtitle-color: $body-color;

$carousel-description-margin: 0;
$carousel-description-font_size: $font-size-base * .875;
$carousel-description-text_shadow: none;
$carousel-description-color: $body-color;

/* owl-carousel > owl-dots */
$carousel-dots-button-background: $primary;
$carousel-dots-button-background--hover: $tertiary;
$carousel-dots-button-background--active: $tertiary;

/* owl-carousel > eyecatcher
   ========================================================================== */
$eyecatcher-min_height_mobile: $carousel-min_height_mobile;
$eyecatcher-min_height: $carousel-min_height;
$eyecatcher-min_height_lg: $carousel-min_height_lg;