.eyecatcher {
	min-height: $eyecatcher-min_height_mobile;

  @include media-breakpoint-up(sm) {
		min-height: $eyecatcher-min_height;
	}

	&.large {
		min-height: $eyecatcher-min_height_mobile;

		@include media-breakpoint-up(sm) {
			min-height: $eyecatcher-min_height_lg;
		}
	}
}

.eyecatcher-owl-carousel {
	min-height: $eyecatcher-min_height_mobile;

  @include media-breakpoint-up(sm) {
		min-height: $eyecatcher-min_height;
	}

	.item {
    min-height: $eyecatcher-min_height_mobile;

    @include media-breakpoint-up(sm) {
      min-height: $eyecatcher-min_height;
		}
	}
}

/* owl-carousel > large
   ========================================================================== */
.eyecatcher-owl-carousel.large {
	min-height: $eyecatcher-min_height_mobile;

	@include media-breakpoint-up(sm) {
		min-height: $eyecatcher-min_height_lg;
	}
	
	.item {
		min-height: $eyecatcher-min_height_mobile;

		@include media-breakpoint-up(sm) {
			min-height: $eyecatcher-min_height_lg;
		}
	}
}