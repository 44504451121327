.footer {
  .footer-main {
    padding: $footer-main-padding;
    background: $footer-main-background;

    .footer-logo {
      max-width: 100%;
      margin-bottom: 20px;

      a { 
        @extend .d-block;
      }

      img {
        @extend .img-fluid;
      }
    }
  }

  .footer-bottom {
    padding: $footer-bottom-padding;
    background: $footer-bottom-background;

    .container-three-columns {
      .container-holder {
         margin: 0 -40px -15px -40px
      }

      .column {
        padding: 0 40px;
      }
    }
  }
}