html,
body {
  font-size: 16px;
}

#TommyBookingSupport {
  .tbs-container-fluid {
    @extend .px-0;
  }

  .tbs-navbar-collapse {
    @extend .px-0;
  }
  
  .tbs-nav {
    li {
      font-size: 14px;
    }
  }
}