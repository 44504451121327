/* bootstrap > shades
   ========================================================================== */
$gray-600: #8C8C8C;
$black:    #000000;
   
/* bootstrap > colors
   ========================================================================== */
$blue:     #1B2F52;
$red:      #D96459;

$creme:    #D7CEBF;
$light-blue: #2B8696;

/* bootstrap > theme-colors
   ========================================================================== */
$primary:       $red;
$secondary:     $blue;
$dark:          $black;

$tertiary:		 $light-blue;

$theme-colors: (
	"tertiary":	$tertiary,
);

/* bootstrap > body
   ========================================================================== */
$link-color:                              $tertiary;
$link-hover-color:                        darken($link-color, 15%);

/* bootstrap > font
	========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@300;400;500;600;700&display=swap');

$font-family-poppins: 'Poppins', sans-serif;
$font-family-dancing-script: 'Dancing Script', cursive;
$font-family-base: $font-family-poppins;

/* bootstrap > buttons
	========================================================================== */
$btn-padding-y:               12px;
$btn-padding-x:               28px;