.mini-sab {
  h2 {
    margin: 0 0 25px 0;

    font-size: $font-size-base * 1.5;
    color: $white;
  }

  h3 {
    margin: 0 0 15px 0;

    font-family: $font-family-dancing-script;
    font-size: $font-size-base * 1.5;
    font-weight: $font-weight-base;
    color: $white;
  }
}

&.home {
  .mini-sab {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 200px;
      left: 60px;
      z-index: 100;

      max-width: 400px;
    }

    .container-default {
      @extend .px-0;
    }

    h2 {
      @extend .text-center;
    }

    h3 {
      @extend .text-center;
    }
  }
}