.add-arrow {
  &--before {
    &::before {
      content: '\f138';
    } 

    &-r {
      &::before {
        content: '\f137';
      }
    }
  }

  &--after {
    &::after {
      content: '\f138';
    }

    &-r {
      &::after {
        content: '\f137';
      }
    }
  }
}