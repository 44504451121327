.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &::after {
      display: block;
      margin: 10px 0 0 0;

      border-top: 1px solid $black;
      content: '';
    }
  }

  ul.custom-list,
  ol.custom-list  {
    li {
      margin: 0 0 10px 0;
      text-transform: uppercase;
    }
  }

  ul.custom-list-horizontal,
  ol.custom-list-horizontal {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-center;

    li {
      margin: 0 25px;
    }
  }
}

/* content > bg-dark
   ========================================================================== */
.content.bg-dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &::after {
      border-top: 1px solid $white;
    }
  }
}

/* content > text-center
   ========================================================================== */
.content.text-center {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &::after {
      max-width: 300px;
      margin: 10px auto 0 auto;
    }
  }
}

/* content > gallery-section
   ========================================================================== */
.content.gallery-section {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &::after {
      @extend .d-none;
    }
  }

  h3 {
    margin: 0 0 15px 0;

    font-family: $font-family-dancing-script;
    font-size: $font-size-base * 1.5;
    font-weight: $font-weight-base;
  }

  h2 {
    margin: 0;
    font-size: $font-size-base * 1.5;
  }

  img {
    @extend .img-fluid;
    @extend .w-100;
  }
}