.owl-carousel {
  min-height: $carousel-min_height_mobile;

  @include media-breakpoint-up(sm) {
		min-height: $carousel-min_height;
	}

  .item {
    min-height: $carousel-min_height_mobile;

    @include media-breakpoint-up(sm) {
      min-height: $carousel-min_height;
    }

    .owl-container {
      max-width: 2000px !important;
      @extend .px-0;
    }

    .owl-caption {
      padding: $carousel-caption-padding;

      @include media-breakpoint-up(sm) {
        padding: $carousel-caption-padding_sm;
      }
    }
  }

  .owl-nav,
  .owl-dots {
    @extend .d-none;
  }
}

/* owl-carousel > large
   ========================================================================== */
.owl-carousel.large {
  min-height: $carousel-min_height_mobile;

  @include media-breakpoint-up(sm) {
		min-height: $carousel-min_height_lg;
	}
  
  .item {
    min-height: $carousel-min_height_mobile;
    padding: $carousel-item_padding_lg;

    @include media-breakpoint-up(sm) {
      min-height: $carousel-min_height_lg;
    }
  }
}

.owl-carousel {
  &.slider {
    .item {
      padding: 5px;
    }
  }
}