.card {
  .card-caption {
    .card-title,
    .card-subtitle {
      &::after {
        @extend .d-none;
      }
    }

    .card-description {
      .list {
        @extend .d-none;
      }
    }
  }

  .card-buttons {
    @extend .d-flex;
    @extend .justify-content-center;
  }

  .card-btn {
    background: $black;
    border: $btn-border-width solid $black;

    color: $white;

    &:hover {
      background: $primary;
      border: $btn-border-width solid $primary;

      color: $white;
    }
  }
}

/* card > card-overlay
   ========================================================================== */
.card.card-overlay {
  @extend .justify-content-center;
  box-shadow: none;
  
  .card-img-overlay {
    position: relative;
  }
  
  .card-caption {
    @extend .d-flex;
    @extend .flex-column;

    .card-title {
      @extend .order-1;
    }

    .card-subtitle {
      @extend .order-0;
      margin: 0 0 15px 0;

      font-family: $font-family-dancing-script;
      font-size: $font-size-base * 1.5;
      font-weight: $font-weight-base;
    }

    .card-description {
      @extend .d-block;
      @extend .d-sm-block;
      @extend .order-3;
    }
  }
}

/* card > card-dark
   ========================================================================== */
.card.dark {
  @extend .flex-row;
  @extend .flex-wrap;

  .card-image {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }
    
    @include media-breakpoint-up(xl) {
      flex: 0 0 250px;
      max-width: 250px;
    }
  }

  .card-body {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      flex: 1 1 0;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }
    
    @include media-breakpoint-up(xl) {
      flex: 1 1 0;
    }
  }
  
  .card-caption {
    .card-title {
      @extend .add-arrow--after;

      &::after {
        @extend .d-inline;
      }
    }
  }
}

/* card > home-blocks
   ========================================================================== */
.home-blocks {
  .card {
    .card-caption {
      .card-title {
        font-size: $font-size-base * 1.125;
      }
    }

    .card-buttons {
      @extend .d-none;
    }
  }
}

/* card > inspiration
   ========================================================================== */
.inspiration {
  .item {
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      .card-img-overlay {
        @extend .d-none;
      }
    }
  }
}