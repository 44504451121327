.header {
  .header-main {
    background: #020A0A;

    .column.navbar {
      padding: 20px 30px;

      @include media-breakpoint-up(sm) {
        padding: 20px 60px;
      }
    }

    .logo {
      max-width: 100px;

      @include media-breakpoint-up(xl) {
        max-width: 190px;
      }
    }

    .navbar-toggler {
      @extend .order-0;
      color: $white;
    }

    .navbar-collapse {
      @extend .order-2;
      @extend .order-xl-1;

      .navbar-nav {
        .nav-item {
          margin: 0 50px 0 0;

          .nav-link {
            padding: 5px 0;
            border-bottom: 2px solid transparent;

            font-weight: $font-weight-medium;
            text-transform: uppercase;
            color: $white;

            transition: $transition-base;

            &:hover {
              border-bottom: 1px solid $white;
            }
          }

          &.active {
            .nav-link {
              border-bottom: 1px solid $white;
              color: $white;
            }
          }
        }

        .dropdown-menu {
          margin: 0;
          border: 0;
          border-radius: 0;

          .nav-item {
            margin: 0;
            
            .nav-link {
              padding: 5px 10px;

              font-size: $font-size-base * .875;
              text-transform: none;
              color: $body-color;

              &:hover {
                color: $primary;
              }
            }
          }
        }
      }

      &.show {
        .navbar-nav {
          .nav-item {
            margin: 0;
          }

          .dropdown-menu {
            background: transparent;

            .nav-item {
              .nav-link {
                padding: 5px 10px 5px 20px;
                color: $white;
              }
            }
          }
        }
      }
    }

    .site_switcher {
      @extend .order-1;
      @extend .order-xl-2;

      .language {
        .nav-item {
          .nav-link {
            padding: 10px;

            font-weight: $font-weight-light;
            text-transform: uppercase;
            color: $white;
          }
        }

        .dropdown-menu {
          @extend .position-absolute;

          .dropdown-item {
            padding: 5px 10px;

            text-transform: uppercase;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }

/* Header - Sticky
  ========================================================================== */
  &.sticky {
    .header-main {
      .logo {
        @include media-breakpoint-up(xl) {
          max-width: 120px;
        }
      }
    }
  }
}

.header + .mini-sab {
  margin-top: 114px;
  padding: 15px 0 15px 0;

  h2,
  h3 {
    @extend .d-none;
  }
}

.header + .main {
  margin-top: 115px;
}