&.home {
  .intro {
    position: relative;
    padding: 5vh 0 2.5vh 0;

    &::before {
      position: absolute;
      top: 0;
      right: 30px;
      left: 30px;

      @extend .d-none;
      @extend .d-md-block;

      border-top: 1px solid $black;
      content: '';

      @include media-breakpoint-up(sm) {
        right: 60px;
        left: 60px;
      }
    }
  }
  
  .home-blocks { padding: 2.5vh 0 2.5vh 0; }

  .content { padding: 10vh 0 5vh 0; }

  .accommodations-content-top { padding: 5vh 0 2.5vh 0; }

  .accommodations-content-bottom { padding: 7vh 0 10vh 0; }

	.gallery-section {
    padding: 5vh 0;
    background: $creme;
  }

  .home-double { padding: 10vh 0 5vh 0; }
}