.home-blocks {
  .page_block {
    &.contentblock {
      &.grid {
        margin: 0 -15px -30px -15px;

        .item {
          margin: 0 0 30px 0;
          
          @include media-breakpoint-up(sm) {
            @include make-col(12);
          }

          @include media-breakpoint-up(md) {
            @include make-col(12);
          }

          @include media-breakpoint-up(lg) {
            @include make-col(6);
          }

          @include media-breakpoint-up(xl) {
            @include make-col(6);
          }
        }
      }
    }
  }
}