.page_block {
  &.inspiration {
    margin: 0 -15px -30px -15px;
    
    .item {
      margin: 0 0 30px 0;
    }

    .item:nth-child(1),
    .item:nth-child(2) {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }

    .item:nth-child(3),
    .item:nth-child(4) {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
    }

    .item:nth-child(5),
    .item:nth-child(6) {
      @include make-col(6);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }
  }
}