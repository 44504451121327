.vocabulary_term_list {
  @extend .justify-content-center;

  li {
    margin: 10px;

    a {
      @extend .btn;
      @extend .btn-secondary;
    }
  }
}

.footer {
  .vocabulary_term_list {
    li {
      margin: 5px 35px;

      a {
        @extend .p-0;

        background: transparent;
        border: 0;

        font-weight: $font-weight-medium;
        color: $primary;

        &:hover {
          color: $tertiary;
        }

        i {
          font-size: $font-size-base * 1.5;
        }
      }
    }
  }
}

.footer_logolink {
  .list {
    @extend .justify-content-center;

    .list-item {
      margin: 0 35px;

      .link {
        color: $primary;
      }
    }
  }
}

.footer_list {
  @extend .text-center;
  @extend .text-sm-left;

  h5 {
    font-size: $font-size-base * .875;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;

    &::after {
      max-width: 300px;
      margin: 10px auto 0 auto;

      @include media-breakpoint-up(sm) {
        max-width: unset;
        margin: 10px 0;
      }
    }
  }

  .list {
    .list-item {
      font-size: $font-size-base * .875;
      font-weight: $font-weight-medium;
      line-height: 24px;

      .link {
        color: $white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.footer_link {
  .list {
    .list-item {
      margin: 0 25px 0 0;

      font-size: $font-size-base * .875;
      font-weight: $font-weight-light;

      .link {
        color: $white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}